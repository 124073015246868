<template>
  <v-app id="materialpro" :class="`${!$vuetify.breakpoint.smAndDown ? 'full-sidebar' : 'mini-sidebar'}`">
      <router-view />
  </v-app>

</template>
<style>
    .horizontalstyle .v-main {
    padding-bottom: 0px !important;
    }

::-webkit-scrollbar {
    background-color:#fff;
    width:16px
}

/* background of the scrollbar except button or resizer */
::-webkit-scrollbar-track {
    background-color:#fff
}
::-webkit-scrollbar-track:hover {
    background-color:#f4f4f4
}

/* scrollbar itself */
::-webkit-scrollbar-thumb {
    background-color:#babac0;
    border-radius:16px;
    border:5px solid #fff
}
::-webkit-scrollbar-thumb:hover {
    background-color:#a0a0a5;
    border:4px solid #f4f4f4
}

/* set button(top and bottom of the scrollbar) */
::-webkit-scrollbar-button {display:none}

</style>
<script>

export default {
  name: 'App',
  components: {
    
  }

};
</script>
