import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
Vue.use(Router);
const router = new Router({
        mode: "history",
        base: process.env.BASE_URL,
        // This is for the scroll top when click on any router link
        scrollBehavior: (to, from, savedPosition) => {
                let scrollTo = 0;
                if (to.hash) {
                        scrollTo = to.hash;
                } else if (savedPosition) {
                        scrollTo = savedPosition.y;
                }

                return goTo(scrollTo);
        },
        // This is for the scroll top when click on any router link
        routes: [
                {
                        path: "/confirm/:userId/:token",
                        name: "login",
                        component: () => import("@/views/profile/confirm"),
                },
                {
                        path: "/",
                        component: () => import("@/layouts/full-layout/Layout"),
                        meta: { auth: true },
                        props: true,
                        children: [
                                {
                                        name: "index",
                                        path: "/",
                                        component: () => import("@/views/index"),
                                },
                                {
                                        name: "contracts",
                                        path: "/contracts",
                                        component: () => import("@/views/contracts/list"),
                                },
                                {
                                        name: "contract",
                                        path: "/contract/:id",
                                        component: () => import("@/views/contracts/view"),
                                },
                                {
                                        name: "edu-orgs",
                                        path: "/edu-orgs",
                                        component: () => import("@/views/eduOrg/index"),
                                },
                                {
                                        name: 'courses',
                                        path: 'courses',
                                        component: () => import('@/views/courses/editorIndex'),
                                },
                                {
                                        name: 'course',
                                        path: 'course/:id/:mode?',
                                        component: () => import('@/views/courses/course'),
                                },
                                {
                                        name: 'regional',
                                        path: 'regional',
                                        component: () => import('@/views/regional/regional'),
                                },
                                {
                                        name: 'my-courses',
                                        path: 'my-courses',
                                        component: () => import('@/views/courses/my-courses'),
                                },
                                {
                                        name: 'my-course',
                                        path: 'my-course/:id',
                                        component: () => import('@/views/courses/courseView'),
                                },
                                {
                                        name: 'my-contracts',
                                        path: 'my-contracts',
                                        component: () => import('@/views/contracts/contracts'),
                                },
                                {
                                        name: 'lesson',
                                        path: 'lesson/:id/:mode?',
                                        component: () => import('@/views/courses/lesson'),
                                },
                                {
                                        name: 'room',
                                        path: 'room/:contractId/:lessonId',
                                        component: () => import('@/views/courses/lesson'),
                                },
                                {
                                        name: 'logs',
                                        path: 'logs',
                                        component: () => import('@/views/logs/logs'),
                                },
                                {
                                        name: 'users',
                                        path: 'users',
                                        component: () => import('@/views/admin/users'),
                                },
                        ],
                },
                {
                        path: '/authentication',
                        component: () => import('@/layouts/auth-layout/Authlayout'),
                        children: [
                                {
                                        name: 'login',
                                        path: 'login',
                                        component: () => import('@/views/authentication/FullLogin'),
                                },
                                {
                                        name: 'register',
                                        path: 'register',
                                        component: () => import('@/views/authentication/FullRegister'),
                                },
                                {
                                        name: 'Error',
                                        path: 'error',
                                        component: () => import('@/views/authentication/Error'),
                                },
                                {
                                        name: 'Recovery',
                                        path: 'recovery',
                                        component: () => import('@/views/authentication/FullReset'),
                                },
                                {
                                        name: 'Reconfirm',
                                        path: 'reconfirm',
                                        component: () => import('@/views/authentication/Reconformation'),
                                }
                        ]
                },
        ],
});
import NProgress from "nprogress";
router.beforeResolve((to, from, next) => {
        // If this isn't an initial page load.
        if (to.name) {
                // Start the route progress bar.
                NProgress.start(800);
        }
        next();
});
router.afterEach(() => {
        // Complete the animation of the route progress bar.
        NProgress.done();
});
export default router;
