import Vue from "vue";
import App from "./App.vue";
import vuetify from "./plugins/vuetify";
import router from "./router";
import store from "./store/store";
import Vuebar from "vuebar";
import "./plugins/base";
import VueSkycons from "vue-skycons";
Vue.use(VueSkycons, {
  color: "#1e88e5",
});

Vue.config.productionTip = false;
Vue.use(Vuebar);

import moment from 'moment-timezone'
import 'moment/locale/ru'
        Vue.use(require('vue-moment'), {
            moment
        })
        
import wysiwyg from "vue-wysiwyg";
Vue.use(wysiwyg, {}); // config is optional. more below
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
import axios from 'axios'
//import axios from 'axios'
import VueAxios from 'vue-axios'
Vue.use(VueAxios, axios)
Vue.router = router

if (process.env.NODE_ENV === 'development') {
    Vue.axios.defaults.baseURL = 'http://127.0.0.1:85/v2/'
    Vue.axios.defaults.apiURL = 'http://127.0.0.1:85/'
  } else {
    Vue.axios.defaults.baseURL = 'https://api.vdpo-study.ru/v2/'
    Vue.axios.defaults.apiURL = 'https://api.vdpo-study.ru/'
  }


import VueParticles from 'vue-particles'
Vue.use(VueParticles)
const VueUploadComponent = require('vue-upload-component')
Vue.component('file-upload', VueUploadComponent)
import auth            from '@websanova/vue-auth/dist/v2/vue-auth.common.js';
import driverAuthBearer      from '@websanova/vue-auth/dist/drivers/auth/bearer.js';
import driverHttpAxios       from '@websanova/vue-auth/dist/drivers/http/axios.1.x.js';
import driverRouterVueRouter from '@websanova/vue-auth/dist/drivers/router/vue-router.2.x.js';
Vue.use(auth, {
    options:{
       authRedirect: '/authentication/login',  
    },
    plugins: {
        http: Vue.axios, // Axios
        // http: Vue.http, // Vue Resource
        router: Vue.router,
    },
    drivers: {
        auth: driverAuthBearer,
        http: driverHttpAxios, // Axios
        // http: driverHttpVueResource, // Vue Resource
        router: driverRouterVueRouter,
    }
})

Vue.axios.interceptors.response.use(response => {
    return response;
}, (error) => {
    if (error.response) {
        switch (error.response.status) {
            // Вернуть 401, очистить информацию о токене и перейти на страницу входа
            case 401:
                localStorage.removeItem('token');
                Vue.router.replace({
                    path: '/authentication/login'
                            // Перейти на текущую страницу после успешного входа
                            // query: {redirect: router.currentRoute.fullPath}
                })

        }
        // Возвращаем сообщение об ошибке, возвращаемое интерфейсом
        return Promise.reject(error.response.data);
    }
    return Promise.reject(error)
});

new Vue({
  vuetify,
  store,
  router,
  render: (h) => h(App),
}).$mount("#app");
